var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Stack, InputAdornment, TextField, Button } from '@mui/material';
import React from 'react';
import Iconify from '../iconify';
export default function TableToolbar(_a) {
    var isFiltered = _a.isFiltered, search = _a.search, onSearch = _a.onSearch, onResetFilter = _a.onResetFilter, placeholder = _a.placeholder, children = _a.children, _b = _a.sx, sx = _b === void 0 ? {} : _b;
    return (_jsxs(Stack, __assign({ spacing: 2, alignItems: "center", direction: {
            xs: 'column',
            sm: 'row',
        }, sx: __assign({ px: 2.5, py: 3 }, sx) }, { children: [_jsx(TextField, { fullWidth: true, value: search, onChange: onSearch, placeholder: placeholder, InputProps: {
                    startAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(Iconify, { icon: "eva:search-fill", sx: { color: 'text.disabled' } }) }))),
                } }), children, isFiltered && (_jsx(Button, __assign({ color: "error", sx: { flexShrink: 0 }, onClick: onResetFilter, startIcon: _jsx(Iconify, { icon: "eva:trash-2-outline" }) }, { children: "Clear" })))] })));
}
